import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import {
  FaAnchor,
  FaCar,
  FaChair,
  FaCogs,
  FaDoorOpen,
  FaFan,
  FaPhone,
  FaTachometerAlt,
} from "react-icons/fa";

import img1 from "../../img/offer-toyota.png";
import img2 from "../../img/nissan-offer.png";
import img3 from "../../img/audi-offer.png";
import img4 from "../../img/bmw-offer.png";
import img5 from "../../img/toyota-offer-2.png";
import img6 from "../../img/marcedes-offer.png";

import "./style.css";
import { carsList } from "../../data/data";

const HotOffers = () => {
  const { t } = useTranslation();

  const onClick = (e) => {
    e.preventDefault();
  };

  return (
    <section className="gauto-offers-area section_70" id="book">
      <Container>
        <Row>
          <Col md={12}>
            <div className="site-heading">
              <h2>Rent A Car</h2>
            </div>
          </Col>
        </Row>
        <Row>
          {carsList.map((car) => (
            <Col lg={4} key={car.id}>
              <div className="single-offers">
                <div className="offer-image">
                  <Link to={`/car-booking/${car.slug}`}>
                    <img src={car.images[0]} alt="offer 1" />
                  </Link>
                </div>
                <div className="offer-text">
                  <Link to={`/car-booking/${car.slug}`}>
                    <h3>{car.carName}</h3>
                  </Link>
                  <h4>${car.price}</h4>
                  <ul>
                    <li>
                      <FaCogs />
                      {car.engineType}
                    </li>
                    <li>
                      <FaChair />
                      {car.seats} seats
                    </li>
                    <li>
                      <FaDoorOpen />
                      Doors: {car.doors}
                    </li>
                    <li>
                      <FaFan />
                      {car.ac && "Air Condition"}
                    </li>
                    <li>
                      <FaCar />
                      {car.radio && "Radio"}
                    </li>
                  </ul>
                  <div className="offer-action">
                    <Link
                      to={`/car-booking/${car.slug}`}
                      className="offer-btn-1"
                    >
                      Rent
                    </Link>
                    <Link
                      to={`/car-booking/${car.slug}`}
                      className="offer-btn-2"
                    >
                      Details
                    </Link>
                   
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default HotOffers;
