import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/header";
import PageTitle from "../../components/PageTitle";
import CarBooking from "../../components/CarBooking";
import Footer from "../../components/Footer";
import { useParams, Navigate } from "react-router-dom";
import { carsList } from "../../data/data";
const CarBookingPage = () => {
  const routeParams = useParams();
  const newList = carsList.find((cars) => cars.slug === routeParams.id);
  console.log("🚀 ~ file: index.js:13 ~ CarBookingPage ~ newList:", newList);

  return (
    <>
      {!newList ? <Navigate replace to="/error" />:
      <Fragment>
        <Header />
        <PageTitle pageTitle={"Rent ride"} pagesub={"Ride details"} />
        <CarBooking />
        <Footer />
      </Fragment>}
    </>
  );
};
export default CarBookingPage;
