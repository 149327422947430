import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaEye, FaRegComments } from "react-icons/fa";

import img1 from "../../img/blog-1.jpg";
import img2 from "../../img/blog-2.jpg";
import img3 from "../../img/blog-3.jpg";

import "./style.css";
import Header from "../header";
import Footer from "../Footer";

export default function OfferContainer({ imageUri, title, list }) {

  return (
    <Col lg={4}>
      <div className="single-blog">
        <div className="blog-image">
          <img src={imageUri} alt={title} />
        </div>
        <div className="blog-text">
          <h3>{title}</h3>
          <div className="blog-meta-home">
            <ul>
              {list.map((services,idx) => (
                <li key={idx}>{services}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Col>
  );
}
