import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaEye, FaRegComments } from "react-icons/fa";

import img1 from "../../img/blog-1.jpg";
import img2 from "../../img/blog-2.jpg";
import img3 from "../../img/blog-3.jpg";

import "./style.css";
import Header from "../header";
import Footer from "../Footer";
import OfferContainer from "./offeredContainer";
import { servicesOffered } from "../../data/data";

const Blog = () => {
  const { t } = useTranslation();

  return (
    <section className="">
      <Header />
      <Container style={{ marginTop: "40px", marginBottom: "40px" }}>
        <Row>
          <Col md="12">
            <div className="site-heading">
              <h2>Services Offered</h2>
            </div>
          </Col>
        </Row>
        <Row>
          {servicesOffered.map((service) => (
            <OfferContainer key={service.id} {...service} />
          ))}
        </Row>
      </Container>
      <Footer />
    </section>
  );
};

export default Blog;
