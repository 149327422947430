import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

import img1 from "../../img/toyota-offer-2.png";
import offer from '../../img/offer.png'

import "./style.css";
import { FaWhatsapp} from "react-icons/fa";
const Promo = () => {


  return (
    <section className="gauto-promo-area">
      <Container>
        <Row className="align-items-center">
          <Col md="6">
            <div className="promo-box-left">
              <img src={offer} alt="promo car" />
            </div>
          </Col>
          <Col md="6">
            <div className="promo-box-right">
              <h3>Rent a car , Today</h3>
              <br />
              <a href="https://wa.me/16786814518" target='_blank'  className="gauto-btn" rel="noreferrer">
                  <FaWhatsapp/> WhatsApp
                </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Promo;
