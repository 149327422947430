import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaHeart,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaSkype,
  FaPaperPlane,
} from "react-icons/fa";

import logo from "../../img/logo.svg";
import img1 from "../../img/post-thumb-3.jpg";
import img2 from "../../img/post-thumb-2.jpg";
import img3 from "../../img/post-thumb-1.jpg";

import "./style.css";

const Footer = () => {
  const { t } = useTranslation();

  const onClick = (e) => {
    e.preventDefault();
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <footer className="gauto-footer-area">
      <div className="footer-top-area">
        <Container>
          <Row>
            <Col lg={4}>
              <div className="single-footer">
                <div className="footer-logo">
                  <Link to="/">
                    <img src={logo} width={"70%"} alt="footer-logo" />
                  </Link>
                </div>
                <p>
                Quagmire Global - Car Rentals & Concierge Service
                </p>
                <div className="footer-address">
                  <h3>Reach us</h3>
                  <p>
                    Cooperate office : 2759 Delk Rd SE, Marietta,GA 30067
                    Operation address : 3588 Oakcliff Rd, STE A,
                    Atlanta , GA30340
                  </p>
                  <ul>
                    <li>{"Phone"}: 1 (678) 681-4518 </li>
                    <li>{"Email"}: Info@quagmireglobal.com</li>
                    <li>{"Open"}: 8AM - 7PM</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom-area">
        <Container>
          <Row>
            <Col md={6}>
              <div className="copyright">
                <p>© {new Date().getFullYear()} Quagmire Global.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
