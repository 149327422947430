import React, { Fragment } from "react";
import AllRoute from "../router";
import "./App.css";
import ScrollToTop from "./ScrollToTop";

const App = () => {
  return (
    <Fragment>
      
      <AllRoute />
    </Fragment>
  );
};

export default App;
