import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import signature from "../../img/signature.png";
import Img1 from "../../img/about.jpg";
import "./style.css";
import { aboutUs } from "../../data/data";
const About = () => {
  const { t } = useTranslation();
  return (
    <section className="gauto-about-area section_70">
      <Container>
        <Row>
          <Col lg={6}>
            <div className="about-left">
              <h4>About Us</h4>
              <h2>{aboutUs.title}</h2>
              <p>{aboutUs.content}</p>
              <div className="about-list">
                <ul>
                  {aboutUs.bullet.map((about) => {
                    return (
                      <li key={about.id}>
                        <span>
                          <FaCheck />
                        </span>
                        {about.text}
                      </li>
                    );
                  })}
                </ul>
              </div>

            </div>
          </Col>
          <Col lg={6}>
            <div className="about-right">
              <img src={Img1} alt="car" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default About;
