import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Col, Container, Row, Dropdown } from "react-bootstrap";
import {
  FaPhoneAlt,
  FaSignInAlt,
  FaUserAlt,
  FaSearch,
  FaGlobe,
  FaClock,
  FaWhatsapp,
  FaEnvelope,
} from "react-icons/fa";
import MobileMenu from "../../components/MobileMenu";

import Logo from "../../img/logo.svg";
import globe from "../../img/globe.png";
import clock from "../../img/clock.png";
import "flag-icon-css/css/flag-icons.min.css";
import "./style.css";

const Header = () => {
  return (
    <Fragment>
      <section className="gauto-header-top-area">
        <Container>
          <Row>
            <Col md={6}>
              <div className="header-top-left">
                <p>
                  Need help <FaPhoneAlt /> Call: 1 (678) 681-4518
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <header className="gauto-main-header-area">
        <Container>
          <Row>
            <Col
              md={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="site-logo">
                <a href="/">
                  <img src={Logo} alt="gauto" width={"100%"} />
                </a>
              </div>
            </Col>
            <Col lg={6} sm={9}>
              <div className="header-promo">
                <div className="single-header-promo">
                  <div className="header-promo-icon">
                    <FaGlobe />
                  </div>
                  <div
                    className="header-promo-info"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <p style={{ lineHeight: "18px" }}>
                      <span style={{ fontWeight: "bolder" }}>
                        Cooperate office
                      </span>
                      <br />
                      2759 Delk Rd SE, Marietta,GA 30067
                    </p>
                    <p style={{ lineHeight: "18px" }}>
                      <span style={{ fontWeight: "bolder" }}>
                        Operational office
                      </span>
                      <br />
                      3588 Oakcliff Rd, STE A, Atlanta , GA30340
                    </p>
                  </div>
                </div>
                <div className="single-header-promo">
                  <div className="header-promo-icon">
                    <FaClock />
                  </div>
                  <div className="header-promo-info">
                    <h3>Monday to Saturday</h3>
                    <p>8:00am - 7:00pm</p>
                  </div>
                </div>
              </div>
            </Col>
            <div
              className="col-lg-3"
              style={{ flexDirection: "row", display: "flex", padding: "30px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#0049af",
                  color: "white",
                  paddingLeft: "20px",
                  borderTopLeftRadius: "20px",
                  borderBottomLeftRadius: "20px",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://wa.me/16786814518"
                  style={{ color: "white" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaWhatsapp /> WhatsApp
                </a>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#0049af",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  color: "white",
                  paddingLeft: "10px",
                  paddingRight: "20px",
                }}
              >
                <a
                  style={{ color: "white" }}
                  href="mailto:Info@quagmireglobal.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaEnvelope /> Email
                </a>
              </div>
            </div>
          </Row>
        </Container>
      </header>
      <section className="gauto-mainmenu-area">
        <Container>
          <Row>
            <Col lg={9}>
              <div className="mainmenu">
                <nav>
                  <ul id="gauto_navigation">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>

                    <li>
                      <a href="/#book">Rent a car</a>
                    </li>

                    <li>
                      <Link to="/service">Concierge</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default Header;
