export const aboutUs = {
  title: "Welcome to Quagmire Global",
  content:
    "Quagmire Global offers more than just car rental – we provide a gateway to seamless travel. As a premier car rental and concierge service, we pride ourselves on delivering not only a diverse fleet of vehicles, but also a comprehensive suite of personalized assistance.\n\nWith Quagmire Global, your journey is our priority. From luxurious sedans to versatile SUVs, our meticulously maintained vehicles suit every occasion. Beyond the road, our dedicated concierge team stands ready to handle reservations, create tailored itineraries, and manage the finer details, ensuring your travel experience is nothing short of extraordinary.",
  vision:
    "Our vision at Quagmire Global is to be the leading name in the car rental industry, known for our commitment to innovation, reliability, and customer satisfaction. We aim to transform the way people perceive car rentals by offering a seamless blend of quality vehicles, affordability, and personalized service.",
  mission:
    "Our mission is to empower your travels with the freedom to explore, the joy of driving, and the confidence of a reliable partner. We strive to deliver a diverse fleet of vehicles to cater to your unique needs, whether it's a family vacation, a business trip, or an adventurous escapade.",
  bullet: [
    { id: 1, text: "Premium Fleet" },
    { id: 2, text: "Convenience" },
    { id: 3, text: "Quality Assurance" },
    { id: 4, text: "Customer Centric Approach" },
  ],
};

export const PHONE = "16786814518";
export const carsList = [
  {
    id: 0,
    slug: "hyundai",
    carName: "Hyundai Veloster",
    engineType: "Automatic Transmission",
    price: 35,
    images: ["hyundai/main.jpg", "hyundai/back.jpg", "hyundai/interior.jpg"],
    doors: 2,
    seats: 5,
    ac: true,
    radio: true,
  },
  {
    id: 1,
    carName: "Toyota Corolla",
    slug: "corolla",
    engineType: "Automatic Transmission",
    price: 45,
    doors: 4,
    seats: 5,
    images: ["corolla/main.jpg", "corolla/engine.jpg", "rav/interior.jpg"],

    ac: true,
    radio: true,
  },
  {
    id: 2,
    carName: "Toyota Camry",
    slug: "camry",
    engineType: "Automatic Transmission",
    price: 55,
    doors: 4,
    seats: 5,
    ac: true,
    images: ["camry/main.jpg", "camry/main2.jpg", "camry/interior.jpg"],
    radio: true,
  },
  {
    id: 3,
    slug: "rav-4",
    carName: "Toyota Rav 4",
    engineType: "Automatic Transmission",
    price: 65,
    doors: 4,
    images: ["rav/main.jpg", "rav/main2.jpg", "rav/interior.jpg"],

    seats: 5,
    ac: true,
    radio: true,
  },
];

export const reviews = [
  {
    id: 1,
    name: "Charlotte B.",
    comment:
      "Very welcoming staff. Great car! it's very clean and great on gas. I will rent with them again",
  },
  {
    id: 2,
    name: "Edward J.",
    comment:
      "The car very nice ride. I needed something with leg room. Very clean and everything up to par. Thanks for the great service will rent again",
  },
  {
    id: 3,
    name: "Attiyah S.",
    comment: "Great customer service! Will rent from again",
  },
  {
    id: 4,
    name: "Ashante M.",
    comment:
      "This is a great, smooth ride. It fir two car seats comfortably with plenty of space for the passenger and myself (driver). Car is very clean and has plenty of trunk space. Definitely would rent again!",
  },
];

export const servicesOffered = [
  {
    id: 0,
    imageUri: "/service/personal.jpg",
    title: "Personal Assistant",
    list: [
      "Run Errands",
      "Grocery & Gift Shopping",
      "Project/Big Purchase Research",
      "Online/In-store Returns",
      "Schedule Appointments",
      "Auto Servicing & Car Wash",
      "Dryclean & Tailoring",
    ],
  },
  {
    id: 1,
    imageUri: "/service/home.jpg",
    title: "Home Management",
    list: [
      "Organizing & Decluttering",
      "Vet/Hire Contractors",
      "Arrange Service& Repair Calls",
      "Owner's Rep: On-site Supervision",
      "Furniture Receiving & Deliveries",
      "Referral Services & Introductions",
      "Vacation sitting/ Dog & Cat Care",
    ],
  },
  {
    id: 3,
    imageUri: "/service/life.jpg",
    title: "Lifestyle Services",
    list: [
      "Vacation & Travel Bookings",
      "On-site Party Troubleshooter",
      "Date Night Reservations",
      "Theater/Concert Tickets",
      "Curated Gifts & Experiences",
      "Personal Shopping",
      "Art & Design Installations",
    ],
  },
  {
    id: 4,
    imageUri: "/service/pro.jpg",
    title: "Professional Support",
    list: [
      "Realtor & Interior Designer Assists ",
      "Travel Bookings",
      "Introductions & Referrals",
      "Conference & Meeting Support",
      "Team Building & Holiday Parties",
      "Internet Research",
      "Team Building & Holiday Parties",
    ],
  },
  {
    id: 5,
    imageUri: "/service/occ.jpg",
    title: "Special Occasions",
    list: [
      "Event Planning & Coordination",
      "Venue Search",
      "Catering Arrangements",
      "Custom Decorations &  Setup",
      "Kitchen Prep",
      "Dinner Party Service & Cleanup",
      "Ingredient & Wine Sourcing",
    ],
  },
];
