import React from "react";
import { Link } from "react-router-dom";
import {
  DatePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, } from "react-bootstrap";
import {
  FaStar,
  FaStarHalfAlt,
  FaCar,
  FaCogs,
  FaTachometerAlt,
  FaWhatsapp,
  FaDesktop,
  FaKey,
  FaLock,
  FaChair,
  FaDoorOpen,
  FaFan,
  FaPhone,
  FaEnvelope,
} from "react-icons/fa";

import img1 from "../../img/booking.jpg";
import img2 from "../../img/master-card.jpg";
import img3 from "../../img/paypal.jpg";
import { useParams, Navigate } from "react-router-dom";
import "./style.css";

import { carsList } from "../../data/data";
const CarBooking = () => {
 
  const routeParams = useParams();
  const car = carsList.find((cars) => cars.slug === routeParams.id);
  console.log("🚀 ~ file: index.js:41 ~ CarBooking ~ newList:", car);

  return (
    <>
      <section className="gauto-car-booking section_70">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="car-booking-image">
                <img src={"/"+car?.images[0]} alt="car" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="car-booking-right">
                <p className="rental-tag">Rental</p>
                <h3>{car.carName}</h3>
                <div className="price-rating">
                  <div className="price-rent">
                    <h4>
                      ${car.price}
                      <span>/ day</span>
                    </h4>
                  </div>
              
                </div>
                <p></p>
                <div className="car-features clearfix">
                  <ul>
                    <li>
                      <FaCogs />
                      {car.engineType}
                    </li>
                    <li>
                      <FaChair />
                      {car.seats} seats
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <FaDoorOpen />
                      Doors: {car.doors}
                    </li>
                    <li>
                      <FaFan />
                      {car.ac && "Air Condition"}
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <FaCar />
                      {car.radio && "Radio"}
                    </li>
                  </ul>
                </div>
              </div>
              <a  href={`https://wa.me/16786814518?text=Good day, I'm inquiring about the ${car.carName} from your website listing`} target='_blank'  className="gauto-btn" rel="noreferrer" style={{color:"black"}}>
                  <FaWhatsapp/> Rent this ride
                </a>
                <a  href={`mailto:Info@quagmireglobal.com?subject=${car.carName} Rental&body=Good day, I'm inquiring about the ${car.carName} from your website listing`} target='_blank'  className="gauto-btn" rel="noreferrer" style={{color:"black"}}>
                  <FaEnvelope/> Rent this ride
                </a>
                <a href="tel:+16786814518" className="gauto-btn" rel="noreferrer" style={{color:"black"}}><FaPhone/> Call: 1 (678) 681-4518</a>
            </Col>
            
          </Row>
        </Container>
      </section>
      <section className="gauto-booking-form section_70">
        <Container>
        <h1 className="mb-4">Photos</h1>
          {/* <Row>
            <Col lg={8}>
              <div className="booking-form-left">
                <div className="single-booking">
                  <h3>{t("car_booking.personal_information")}</h3>
                  <form onSubmit={SubmitHandler}>
                    <Row>
                      <Col md={6}>
                        <p>
                          <input
                            type="text"
                            placeholder={t("car_booking.first_name")}
                          />
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          <input
                            type="text"
                            placeholder={t("car_booking.last_name")}
                          />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <p>
                          <input
                            type="email"
                            placeholder={t("car_booking.email")}
                          />
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          <input
                            type="tel"
                            placeholder={t("car_booking.phn")}
                          />
                        </p>
                      </Col>
                    </Row>
                  </form>
                </div>
                <div className="single-booking">
                  <h3>{t("car_booking.booking_details")}</h3>
                  <form>
                    <Row>
                      <Col md={6}>
                        <p>
                          <input type="text" placeholder={t("from_address")} />
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          <input type="text" placeholder={t("to_address")} />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <p>
                          <select>
                            <option data-display="Select">1 person</option>
                            <option>2 person</option>
                            <option>3 person</option>
                            <option>4 person</option>
                            <option>5-10 person</option>
                          </select>
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          <select>
                            <option data-display="Select">1 luggage</option>
                            <option>2 luggage</option>
                            <option>3 luggage</option>
                            <option>4(+) luggage</option>
                          </select>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <p>
                          <DatePickerComponent
                            id="datepicker"
                            placeholder={t("journey_date")}
                          ></DatePickerComponent>
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          <TimePickerComponent
                            id="timepicker"
                            placeholder={t("journey_time")}
                          ></TimePickerComponent>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <textarea
                            placeholder="Write Here..."
                            defaultValue={""}
                          />
                        </p>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="booking-right">
                <h3>{t("car_booking.payment_method")}</h3>
                <div className="gauto-payment clearfix">
                  <div className="payment">
                    <input type="radio" id="ss-option" name="selector" />
                    <label htmlFor="ss-option">
                      {t("car_booking.bank_transfer")}
                    </label>
                    <div className="check">
                      <div className="inside" />
                    </div>
                    <p>{t("car_booking.payment_text")}</p>
                  </div>
                  <div className="payment">
                    <input type="radio" id="f-option" name="selector" />
                    <label htmlFor="f-option">
                      {t("car_booking.check_payment")}
                    </label>
                    <div className="check">
                      <div className="inside" />
                    </div>
                  </div>
                  <div className="payment">
                    <input type="radio" id="s-option" name="selector" />
                    <label htmlFor="s-option">
                      {t("car_booking.credit_card")}
                    </label>
                    <div className="check">
                      <div className="inside" />
                    </div>
                    <img src={img2} alt="credit card" />
                  </div>
                  <div className="payment">
                    <input type="radio" id="t-option" name="selector" />
                    <label htmlFor="t-option">Paypal</label>
                    <div className="check">
                      <div className="inside" />
                    </div>
                    <img src={img3} alt="credit card" />
                  </div>
                </div>
                <div className="action-btn">
                  <Link to="/" onClick={onClick} className="gauto-btn">
                    {t("researve_now")}
                  </Link>
                </div>
              </div>
            </Col>
          </Row> */}
          <Row>
        {car.images.map((image, index) => (
          <Col key={index} md={6} className="mb-4">
            <img src={"/"+image} alt={image.alt} className="img-fluid" />
          </Col>
        ))}
      </Row>
        </Container>
      </section>
    </>
  );
};

export default CarBooking;
