import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";

import img1 from "../../img/about-page.jpg";
import img2 from "../../img/cars.png";

import "./style.css";

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="about-page-area section_70">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="about-page-left">
                <h4>About us</h4>
                <h3>{"We are committed to providing great services to you"}</h3>
                <p>
                  Quagmire Global offers more than just car rental – we provide
                  a gateway to seamless travel. As a premier car rental and
                  concierge service, we pride ourselves on delivering not only a
                  diverse fleet of vehicles, but also a comprehensive suite of
                  personalized assistance.
                  <br />
                  With Quagmire Global, your journey is our priority. From
                  luxurious sedans to versatile SUVs, our meticulously
                  maintained vehicles suit every occasion. Beyond the road, our
                  dedicated concierge team stands ready to handle reservations,
                  create tailored itineraries, and manage the finer details,
                  ensuring your travel experience is nothing short of
                  extraordinary.
                  <br />
                  Choose Quagmire Global for a fusion of convenience,
                  sophistication, and expert service. Redefine the way you
                  travel – with us, it's not just a trip, but an elevated
                  adventure.
                </p>

                
              </div>
            </Col>
            <Col lg={6}>
              <div className="about-page-right">
                <img src={img1} alt="about page" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="gauto-about-promo section_70">
        <Container>
          <Row>
            <Col md={12}>
              <div className="about-promo-text">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: t("about_page.proud_title", {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="about-promo-image">
                <img src={img2} alt="about promo" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutPage;
